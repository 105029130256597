<template>
    <div class="el-content">
        <a-button class="mb12" @click="$router.go(-1)">返回</a-button>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'描述',dataIndex:'description'},
			{title:'操作积分',dataIndex:'change',slots:{customRender:'change'}},
			{title:'操作后积分',dataIndex:'after'},
			{title:'操作时间',dataIndex:'create_time'},
		]" >
            <template #change="{record}">
                <span style="color:#00CC66" v-if="record.type == 1">+{{record.change}}</span>
                <span class="kdred" v-else>-{{record.change}}</span>
            </template>
		</a-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import user from '@/api/user.js'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const options = useRoute().query
        const _d = reactive({
            info:{
                list:[],
                page:1,
                limit:10,
                count:0
            }
        })
        getScoreRecordList(1,_d.info.limit)
        //获取积分记录
        function getScoreRecordList(page,limit){
            user.getScoreRecord(page,limit,options.id).then(res=>{
                _d.info = { limit,...res }
            })
        }
        return {
            ...toRefs(_d),
            getScoreRecordList
        }
    },
}
</script>
<style lang="scss" scoped>
</style>